@use 'styles/util';
@use 'styles/breakpoint';
@use 'rcl/tokens/colorToken';
@use 'rcl/tokens/spacingToken';

.form-details {
    background: colorToken.$global_neutral_grey_50;
    padding: calc(#{util.gutter(1)} - #{spacingToken.$global_spacing_sm}); // 16px - token value = padding+border-spacing
    border-radius: spacingToken.$global_spacing_md;
    display: table;
    width: 100%;
    border-spacing: spacingToken.$global_spacing_sm;

    &-row {
        display: table-row;
    }
    &-cell {
        display: table-cell;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
            width: 0;
            min-width: util.pxToRem(160);
            padding-right: util.gutter(0,2);
        }
    }
}