@use 'styles/util';
@use 'rcl/tokens/colorToken';
@use 'rcl/tokens/borderRadiusToken';

.c-error {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: util.gutter(0, 1);
    padding: util.gutter(0, 1) util.gutter(0, 2);
    border-radius: util.pxToRem(borderRadiusToken.$global_radius_md);
    color: colorToken.$alias_typography_text_dark;

    &__icon {
        color: colorToken.$alias_state_dark_failure;
    }
}