@use 'styles/config';
@use 'styles/breakpoint';
@use 'styles/util';

.c-header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: config.$var-header-background;
    column-gap: util.gutter(1);

    &__area {
        height: 100%;
        display: flex;
        align-items: stretch;
        column-gap: util.gutter(0, 1);

        &--left {
            padding: util.gutter(0, 2);
            column-gap: util.gutter(0, 2);

            @include breakpoint.from-large() {
                column-gap: util.gutter(0, 1);
            }

            > .c-logo {
                padding: util.gutter(0, 1)
            }
        }

        &--right {
            display: flex;
            align-items: center;
            padding: 0 util.gutter(0, 1);
        }
    }
}