@use "../config";
@use "../util";
@use "@gonitro/ui-design-system/dist/styles/colors";

@mixin custom() {
    $border-color: colors.$color-nitro-white; // TODO it's just temp, should be changed when themeing will be there
    $background-color: rgba(colors.$color-nitro-spark-dark, 0.7);
    $background-hover-color: rgba(colors.$color-nitro-spark-dark, 1);

    // Scrollbar styling for chromium based browsers
    &::-webkit-scrollbar {
        width: config.$scrollbar-width;
        height: config.$scrollbar-height;
        position: absolute;
        padding: 0;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        box-sizing: border-box;
        background-color: $background-color;
        border-radius: util.gutter(0, 2);
        border: 2px solid $border-color;
        transition: background-color config.$short-transition-time, border config.$short-transition-time;

        &:hover {
            border: 2px solid $background-hover-color;
            background-color: $background-hover-color;
        }
    }

    // Scrollbar styling for firefox
    scrollbar-width: thin;
    scrollbar-color: $background-color transparent;
}
