@use 'styles/config';
@use 'styles/util';
@use 'styles/mixins';
@use 'styles/breakpoint';
@use 'rcl/tokens/colorToken';

.c-content {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    @include breakpoint.from-medium() {
        max-width: #{util.pxToRem(640) + config.$content-padding-x--medium};
    }
    @include breakpoint.from-large() {
        max-width: #{util.pxToRem(640) + config.$content-padding-x--large};
    }

}