@use 'styles/breakpoint';
@use 'rcl/tokens/colorToken';

.v-confirmation {
    padding-bottom: 2rem;

    a, a:hover, a:visited {
        color: colorToken.$alias_typography_text_darker;
    }
    @include breakpoint.from-medium() {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        height: 100%;
    }
}