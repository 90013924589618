@use 'styles/util';
@use 'rcl/tokens/colorToken';

.c-footer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__copy {
        display: flex;
        align-items: center;
        margin: 0 util.gutter(1);

        &-logo {
            width: util.pxToRem(12);
            margin: 0 util.gutter(0,2) 0 util.gutter(0,1.5);
        }
    }

    &__privacy {
        margin: 0 util.gutter(1);

        &-link {
            text-decoration: underline;
            color: colorToken.$alias_typography_text_darker;
        }
    }
}