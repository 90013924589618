@use 'styles/breakpoint';
@use 'styles/util';
@use 'styles/mixins';

.c-layout {
    background: rgba(0,0,0,0.06); // TODO remove or change to proper bg when possible
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &__header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: util.use-var(--header-height);
    }

    &__sidebar {
        background: rgba(0,0,0,0.03);  // TODO remove or change to proper bg when possible
        position: fixed;
        top: util.use-var(--header-height);
        width: util.use-var(--aside-width);
        height: calc(100% - #{util.use-var(--header-height)});
        overflow: hidden;
    }

    &__content {
        position: fixed;
        top: util.use-var(--header-height);
        left: util.use-var(--aside-width);
        width: calc(100% - #{util.use-var(--aside-width)});
        height: calc(100% - #{util.use-var(--header-height)} - #{util.use-var(--footer-height)});
        padding: util.gutter(2,2);

        @include mixins.scrollbar-custom();
        overflow-y: auto;
    }

    &__footer {
        position: fixed;
        bottom: 0;
        right: 0;
        width: calc(100% - #{util.use-var(--aside-width)});
        height: util.use-var(--footer-height);
        overflow: hidden;
    }
}