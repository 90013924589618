@use '../config';

@function breakpoint($size) {
    @return map-get(config.$breakpoint, $size);
}

// Inserts a media query, content is applicable from $size and wider
@mixin breakpoint($size) {
    @media (min-width: breakpoint($size)) {
        @content
    }
};

// Inserts a media query, content is applicable from $size and smaller
// extra -0.01px is to avoid query and query-down being applied to exact breakpoint size
@mixin breakpoint-down($size) {
    @media (max-width: #{breakpoint($size) - 0.01px}) {
        @content
    }
};

// Inserts a media query, content is applicable from $size and smaller
// extra -0.01px is to avoid query and query-down being applied to exact breakpoint size
@mixin breakpoint-between($size1, $size2) {
    @media (min-width: #{breakpoint($size1)}) and (max-width: #{breakpoint($size2) - 0.01px}) {
        @content
    }
};
