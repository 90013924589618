@use '../util';

@for $i from 0 through 8 {

    .mt--#{$i}, .my--#{$i}, .m--#{$i} {
        margin-top: util.gutter(0, $i);
    }
    .mb--#{$i}, .my--#{$i}, .m--#{$i} {
        margin-bottom: util.gutter(0, $i);
    }
    .ml--#{$i}, .mx--#{$i}, .m--#{$i} {
        margin-left: util.gutter(0, $i);
    }
    .mr--#{$i}, .mx--#{$i}, .m--#{$i} {
        margin-right: util.gutter(0, $i);
    }
}