@use 'util';
@use 'rcl/tokens/colorToken';

$breakpoint: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    full-hd: 1920px,
);

// while there are many breakpoints (and every is important to do smaller changes according to proper rwd)
// some has bigger impact - hide sidebar and show
$breakpoint-small-screens-from: xs;
$breakpoint-medium-screens-from: md;
$breakpoint-large-screens-from: xl;

$unauthorized-layout-mobile-breakpoint: $breakpoint-medium-screens-from;

// to be used by css 'var(--name)'. Remember to wrap it #{config.$var}
$var-header-height: util.gutter(3);
$var-aside-width: util.gutter(20);
$scrollbar-width: util.gutter(0, 2);
$scrollbar-height: $scrollbar-width;
$var-header-background: colorToken.$alias_fills_header;


// Used for small transitions, e.g. a pop-out
$short-transition-time: 150ms;
// Used for large transitions, e.g. a panel
$long-transition-time: 300ms;

$content-padding-x--small: util.gutter(1);
$content-padding-y--small: util.gutter(1);
$content-padding-x--medium: util.gutter(1, 2);
$content-padding-y--medium: util.gutter(1);
$content-padding-x--large: util.gutter(2);
$content-padding-y--large: util.gutter(1, 2);
