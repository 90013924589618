@use 'styles/util';
@use 'rcl/tokens/colorToken';

.c-header-menu {
    ::part(.menu-container) {

        width: 50rem;
    }
    &__item {
        text-align: center;
    }
}
