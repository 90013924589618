@use 'styles/util';
@use 'styles/breakpoint';
@use 'rcl/tokens/colorToken';
@use 'rcl/tokens/spacingToken';

.v-form {
    display: flex;
    flex-direction: column;
    row-gap: util.gutter(1,2);

    &__loader {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.15);
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__details {
        background: colorToken.$global_neutral_grey_50;
        padding: calc(#{util.gutter(1)} - #{spacingToken.$global_spacing_sm}); // 16px - token value = padding+border-spacing
        border-radius: spacingToken.$global_spacing_md;
        display: table;
        width: 100%;
        border-spacing: spacingToken.$global_spacing_sm;

        &-row {
            display: table-row;
        }
        &-cell {
            display: table-cell;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
                width: 0;
                min-width: util.pxToRem(160);
                padding-right: util.gutter(0,2);
            }
        }
    }

    &__submit {
        width: 100%;
    }
}