.c-logo {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;

    > * {
        display: block;
        margin: auto;
        max-width: 100%;
        height: 100%;
        width: auto;
    }
}