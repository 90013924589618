@use 'styles/util';
@use 'styles/breakpoint';
@use 'rcl/tokens/colorToken';
@use 'rcl/tokens/spacingToken';

.form-fields {
    display: flex;
    flex-flow: row wrap;
    gap: util.gutter(1);

    &__field {
        width: 100%;

        @include breakpoint.from-medium() {
            width: calc(50% - #{util.gutter(0,2)});

            &--full {
                width: 100%;
            }
        }

        &--url-prefix {
            display: flex;
            position: relative;
            column-gap: util.gutter(0,1);
        }
    }
    &__url-prefix {
        flex: 1;
        @include breakpoint.up(sm) {
            max-width: calc(50% - #{util.pxToRem(2)});
        }
    }
    &__url-suffix {
        padding-top: calc(1rem * 1.5 + 2px + 8px + 1px); // label font-size * line-height + label margin bottom + input padding + magic pixel
    }
}