@use 'styles/breakpoint';
@use 'rcl/tokens/typographyToken';

%--desktop-display-xxl {
    @include typographyToken.alias_desktop_display_xxl;
}

.c-typography {
    // @postinstallStart - don't remove this line nor modify following content
    &--desktop-display-xxl { @include typographyToken.alias_desktop_display_xxl; }
    &--desktop-display-xl { @include typographyToken.alias_desktop_display_xl; }
    &--desktop-display-lg { @include typographyToken.alias_desktop_display_lg; }
    &--desktop-display-md { @include typographyToken.alias_desktop_display_md; }
    &--desktop-display-sm { @include typographyToken.alias_desktop_display_sm; }
    &--desktop-display-xs { @include typographyToken.alias_desktop_display_xs; }
    &--desktop-header-xl { @include typographyToken.alias_desktop_header_xl; }
    &--desktop-header-lg { @include typographyToken.alias_desktop_header_lg; }
    &--desktop-header-md { @include typographyToken.alias_desktop_header_md; }
    &--desktop-header-sm { @include typographyToken.alias_desktop_header_sm; }
    &--desktop-header-xs { @include typographyToken.alias_desktop_header_xs; }
    &--desktop-description-xl { @include typographyToken.alias_desktop_description_xl; }
    &--desktop-description-lg { @include typographyToken.alias_desktop_description_lg; }
    &--desktop-description-md { @include typographyToken.alias_desktop_description_md; }
    &--desktop-description-sm { @include typographyToken.alias_desktop_description_sm; }
    &--desktop-description-bold-xl { @include typographyToken.alias_desktop_description_bold_xl; }
    &--desktop-description-bold-lg { @include typographyToken.alias_desktop_description_bold_lg; }
    &--desktop-description-bold-md { @include typographyToken.alias_desktop_description_bold_md; }
    &--desktop-description-bold-sm { @include typographyToken.alias_desktop_description_bold_sm; }
    &--desktop-tag-md { @include typographyToken.alias_desktop_tag_md; }
    &--desktop-tag-sm { @include typographyToken.alias_desktop_tag_sm; }
    &--desktop-tag-xs { @include typographyToken.alias_desktop_tag_xs; }
    &--desktop-quote-xl { @include typographyToken.alias_desktop_quote_xl; }
    &--desktop-quote-lg { @include typographyToken.alias_desktop_quote_lg; }
    &--desktop-caption-sm { @include typographyToken.alias_desktop_caption_sm; }
    &--desktop-byline-sm { @include typographyToken.alias_desktop_byline_sm; }
    &--desktop-byline-xs { @include typographyToken.alias_desktop_byline_xs; }
    &--desktop-credit-xs { @include typographyToken.alias_desktop_credit_xs; }
    &--mobile-display-xxl { @include typographyToken.alias_mobile_display_xxl; }
    &--mobile-display-xl { @include typographyToken.alias_mobile_display_xl; }
    &--mobile-display-lg { @include typographyToken.alias_mobile_display_lg; }
    &--mobile-display-md { @include typographyToken.alias_mobile_display_md; }
    &--mobile-display-sm { @include typographyToken.alias_mobile_display_sm; }
    &--mobile-display-xs { @include typographyToken.alias_mobile_display_xs; }
    &--mobile-header-xl { @include typographyToken.alias_mobile_header_xl; }
    &--mobile-header-lg { @include typographyToken.alias_mobile_header_lg; }
    &--mobile-header-md { @include typographyToken.alias_mobile_header_md; }
    &--mobile-header-sm { @include typographyToken.alias_mobile_header_sm; }
    &--mobile-header-xs { @include typographyToken.alias_mobile_header_xs; }
    &--mobile-description-xl { @include typographyToken.alias_mobile_description_xl; }
    &--mobile-description-lg { @include typographyToken.alias_mobile_description_lg; }
    &--mobile-description-md { @include typographyToken.alias_mobile_description_md; }
    &--mobile-description-sm { @include typographyToken.alias_mobile_description_sm; }
    &--mobile-description-bold-xl { @include typographyToken.alias_mobile_description_bold_xl; }
    &--mobile-description-bold-lg { @include typographyToken.alias_mobile_description_bold_lg; }
    &--mobile-description-bold-md { @include typographyToken.alias_mobile_description_bold_md; }
    &--mobile-description-bold-sm { @include typographyToken.alias_mobile_description_bold_sm; }
    &--mobile-tag-md { @include typographyToken.alias_mobile_tag_md; }
    &--mobile-tag-sm { @include typographyToken.alias_mobile_tag_sm; }
    &--mobile-tag-xs { @include typographyToken.alias_mobile_tag_xs; }
    &--mobile-quote-xl { @include typographyToken.alias_mobile_quote_xl; }
    &--mobile-quote-lg { @include typographyToken.alias_mobile_quote_lg; }
    &--mobile-caption-sm { @include typographyToken.alias_mobile_caption_sm; }
    &--mobile-byline-sm { @include typographyToken.alias_mobile_byline_sm; }
    &--mobile-byline-xs { @include typographyToken.alias_mobile_byline_xs; }
    &--mobile-credit-xs { @include typographyToken.alias_mobile_credit_xs; }
    &--icon-light-xxl { @include typographyToken.alias_icon_light_xxl; }
    &--icon-light-xl { @include typographyToken.alias_icon_light_xl; }
    &--icon-light-lg { @include typographyToken.alias_icon_light_lg; }
    &--icon-light-md { @include typographyToken.alias_icon_light_md; }
    &--icon-light-sm { @include typographyToken.alias_icon_light_sm; }
    &--icon-light-xs { @include typographyToken.alias_icon_light_xs; }
    &--icon-heavy-xxl { @include typographyToken.alias_icon_heavy_xxl; }
    &--icon-heavy-xl { @include typographyToken.alias_icon_heavy_xl; }
    &--icon-heavy-lg { @include typographyToken.alias_icon_heavy_lg; }
    &--icon-heavy-md { @include typographyToken.alias_icon_heavy_md; }
    &--icon-heavy-sm { @include typographyToken.alias_icon_heavy_sm; }
    &--icon-heavy-xs { @include typographyToken.alias_icon_heavy_xs; }
    &--icon-brands-xxl { @include typographyToken.alias_icon_brands_xxl; }
    &--icon-brands-xl { @include typographyToken.alias_icon_brands_xl; }
    &--icon-brands-lg { @include typographyToken.alias_icon_brands_lg; }
    &--icon-brands-md { @include typographyToken.alias_icon_brands_md; }
    &--icon-brands-sm { @include typographyToken.alias_icon_brands_sm; }
    &--icon-brands-xs { @include typographyToken.alias_icon_brands_xs; }
    &--ui-buttons-xl { @include typographyToken.alias_ui_buttons_xl; }
    &--ui-buttons-lg { @include typographyToken.alias_ui_buttons_lg; }
    &--ui-buttons-md { @include typographyToken.alias_ui_buttons_md; }
    &--ui-buttons-sm { @include typographyToken.alias_ui_buttons_sm; }
    &--ui-buttons-xs { @include typographyToken.alias_ui_buttons_xs; }
    &--ui-forms-label-lg { @include typographyToken.alias_ui_forms_label_lg; }
    &--ui-forms-label-md { @include typographyToken.alias_ui_forms_label_md; }
    &--ui-forms-label-sm { @include typographyToken.alias_ui_forms_label_sm; }
    &--ui-forms-label-xs { @include typographyToken.alias_ui_forms_label_xs; }
    &--ui-forms-input-lg { @include typographyToken.alias_ui_forms_input_lg; }
    &--ui-forms-input-md { @include typographyToken.alias_ui_forms_input_md; }
    &--ui-forms-input-sm { @include typographyToken.alias_ui_forms_input_sm; }
    &--ui-forms-input-xs { @include typographyToken.alias_ui_forms_input_xs; }
    &--ui-menu-lg { @include typographyToken.alias_ui_menu_lg; }
    &--ui-menu-md { @include typographyToken.alias_ui_menu_md; }
    &--ui-menu-sm { @include typographyToken.alias_ui_menu_sm; }
    &--ui-menu-xs { @include typographyToken.alias_ui_menu_xs; }

    @include breakpoint.from-medium() {
        &--desktop-display-xxl--medium { @include typographyToken.alias_desktop_display_xxl; }
        &--desktop-display-xl--medium { @include typographyToken.alias_desktop_display_xl; }
        &--desktop-display-lg--medium { @include typographyToken.alias_desktop_display_lg; }
        &--desktop-display-md--medium { @include typographyToken.alias_desktop_display_md; }
        &--desktop-display-sm--medium { @include typographyToken.alias_desktop_display_sm; }
        &--desktop-display-xs--medium { @include typographyToken.alias_desktop_display_xs; }
        &--desktop-header-xl--medium { @include typographyToken.alias_desktop_header_xl; }
        &--desktop-header-lg--medium { @include typographyToken.alias_desktop_header_lg; }
        &--desktop-header-md--medium { @include typographyToken.alias_desktop_header_md; }
        &--desktop-header-sm--medium { @include typographyToken.alias_desktop_header_sm; }
        &--desktop-header-xs--medium { @include typographyToken.alias_desktop_header_xs; }
        &--desktop-description-xl--medium { @include typographyToken.alias_desktop_description_xl; }
        &--desktop-description-lg--medium { @include typographyToken.alias_desktop_description_lg; }
        &--desktop-description-md--medium { @include typographyToken.alias_desktop_description_md; }
        &--desktop-description-sm--medium { @include typographyToken.alias_desktop_description_sm; }
        &--desktop-description-bold-xl--medium { @include typographyToken.alias_desktop_description_bold_xl; }
        &--desktop-description-bold-lg--medium { @include typographyToken.alias_desktop_description_bold_lg; }
        &--desktop-description-bold-md--medium { @include typographyToken.alias_desktop_description_bold_md; }
        &--desktop-description-bold-sm--medium { @include typographyToken.alias_desktop_description_bold_sm; }
        &--desktop-tag-md--medium { @include typographyToken.alias_desktop_tag_md; }
        &--desktop-tag-sm--medium { @include typographyToken.alias_desktop_tag_sm; }
        &--desktop-tag-xs--medium { @include typographyToken.alias_desktop_tag_xs; }
        &--desktop-quote-xl--medium { @include typographyToken.alias_desktop_quote_xl; }
        &--desktop-quote-lg--medium { @include typographyToken.alias_desktop_quote_lg; }
        &--desktop-caption-sm--medium { @include typographyToken.alias_desktop_caption_sm; }
        &--desktop-byline-sm--medium { @include typographyToken.alias_desktop_byline_sm; }
        &--desktop-byline-xs--medium { @include typographyToken.alias_desktop_byline_xs; }
        &--desktop-credit-xs--medium { @include typographyToken.alias_desktop_credit_xs; }
        &--mobile-display-xxl--medium { @include typographyToken.alias_mobile_display_xxl; }
        &--mobile-display-xl--medium { @include typographyToken.alias_mobile_display_xl; }
        &--mobile-display-lg--medium { @include typographyToken.alias_mobile_display_lg; }
        &--mobile-display-md--medium { @include typographyToken.alias_mobile_display_md; }
        &--mobile-display-sm--medium { @include typographyToken.alias_mobile_display_sm; }
        &--mobile-display-xs--medium { @include typographyToken.alias_mobile_display_xs; }
        &--mobile-header-xl--medium { @include typographyToken.alias_mobile_header_xl; }
        &--mobile-header-lg--medium { @include typographyToken.alias_mobile_header_lg; }
        &--mobile-header-md--medium { @include typographyToken.alias_mobile_header_md; }
        &--mobile-header-sm--medium { @include typographyToken.alias_mobile_header_sm; }
        &--mobile-header-xs--medium { @include typographyToken.alias_mobile_header_xs; }
        &--mobile-description-xl--medium { @include typographyToken.alias_mobile_description_xl; }
        &--mobile-description-lg--medium { @include typographyToken.alias_mobile_description_lg; }
        &--mobile-description-md--medium { @include typographyToken.alias_mobile_description_md; }
        &--mobile-description-sm--medium { @include typographyToken.alias_mobile_description_sm; }
        &--mobile-description-bold-xl--medium { @include typographyToken.alias_mobile_description_bold_xl; }
        &--mobile-description-bold-lg--medium { @include typographyToken.alias_mobile_description_bold_lg; }
        &--mobile-description-bold-md--medium { @include typographyToken.alias_mobile_description_bold_md; }
        &--mobile-description-bold-sm--medium { @include typographyToken.alias_mobile_description_bold_sm; }
        &--mobile-tag-md--medium { @include typographyToken.alias_mobile_tag_md; }
        &--mobile-tag-sm--medium { @include typographyToken.alias_mobile_tag_sm; }
        &--mobile-tag-xs--medium { @include typographyToken.alias_mobile_tag_xs; }
        &--mobile-quote-xl--medium { @include typographyToken.alias_mobile_quote_xl; }
        &--mobile-quote-lg--medium { @include typographyToken.alias_mobile_quote_lg; }
        &--mobile-caption-sm--medium { @include typographyToken.alias_mobile_caption_sm; }
        &--mobile-byline-sm--medium { @include typographyToken.alias_mobile_byline_sm; }
        &--mobile-byline-xs--medium { @include typographyToken.alias_mobile_byline_xs; }
        &--mobile-credit-xs--medium { @include typographyToken.alias_mobile_credit_xs; }
        &--icon-light-xxl--medium { @include typographyToken.alias_icon_light_xxl; }
        &--icon-light-xl--medium { @include typographyToken.alias_icon_light_xl; }
        &--icon-light-lg--medium { @include typographyToken.alias_icon_light_lg; }
        &--icon-light-md--medium { @include typographyToken.alias_icon_light_md; }
        &--icon-light-sm--medium { @include typographyToken.alias_icon_light_sm; }
        &--icon-light-xs--medium { @include typographyToken.alias_icon_light_xs; }
        &--icon-heavy-xxl--medium { @include typographyToken.alias_icon_heavy_xxl; }
        &--icon-heavy-xl--medium { @include typographyToken.alias_icon_heavy_xl; }
        &--icon-heavy-lg--medium { @include typographyToken.alias_icon_heavy_lg; }
        &--icon-heavy-md--medium { @include typographyToken.alias_icon_heavy_md; }
        &--icon-heavy-sm--medium { @include typographyToken.alias_icon_heavy_sm; }
        &--icon-heavy-xs--medium { @include typographyToken.alias_icon_heavy_xs; }
        &--icon-brands-xxl--medium { @include typographyToken.alias_icon_brands_xxl; }
        &--icon-brands-xl--medium { @include typographyToken.alias_icon_brands_xl; }
        &--icon-brands-lg--medium { @include typographyToken.alias_icon_brands_lg; }
        &--icon-brands-md--medium { @include typographyToken.alias_icon_brands_md; }
        &--icon-brands-sm--medium { @include typographyToken.alias_icon_brands_sm; }
        &--icon-brands-xs--medium { @include typographyToken.alias_icon_brands_xs; }
        &--ui-buttons-xl--medium { @include typographyToken.alias_ui_buttons_xl; }
        &--ui-buttons-lg--medium { @include typographyToken.alias_ui_buttons_lg; }
        &--ui-buttons-md--medium { @include typographyToken.alias_ui_buttons_md; }
        &--ui-buttons-sm--medium { @include typographyToken.alias_ui_buttons_sm; }
        &--ui-buttons-xs--medium { @include typographyToken.alias_ui_buttons_xs; }
        &--ui-forms-label-lg--medium { @include typographyToken.alias_ui_forms_label_lg; }
        &--ui-forms-label-md--medium { @include typographyToken.alias_ui_forms_label_md; }
        &--ui-forms-label-sm--medium { @include typographyToken.alias_ui_forms_label_sm; }
        &--ui-forms-label-xs--medium { @include typographyToken.alias_ui_forms_label_xs; }
        &--ui-forms-input-lg--medium { @include typographyToken.alias_ui_forms_input_lg; }
        &--ui-forms-input-md--medium { @include typographyToken.alias_ui_forms_input_md; }
        &--ui-forms-input-sm--medium { @include typographyToken.alias_ui_forms_input_sm; }
        &--ui-forms-input-xs--medium { @include typographyToken.alias_ui_forms_input_xs; }
        &--ui-menu-lg--medium { @include typographyToken.alias_ui_menu_lg; }
        &--ui-menu-md--medium { @include typographyToken.alias_ui_menu_md; }
        &--ui-menu-sm--medium { @include typographyToken.alias_ui_menu_sm; }
        &--ui-menu-xs--medium { @include typographyToken.alias_ui_menu_xs; }
    }

    @include breakpoint.from-large() {
        &--desktop-display-xxl--large { @include typographyToken.alias_desktop_display_xxl; }
        &--desktop-display-xl--large { @include typographyToken.alias_desktop_display_xl; }
        &--desktop-display-lg--large { @include typographyToken.alias_desktop_display_lg; }
        &--desktop-display-md--large { @include typographyToken.alias_desktop_display_md; }
        &--desktop-display-sm--large { @include typographyToken.alias_desktop_display_sm; }
        &--desktop-display-xs--large { @include typographyToken.alias_desktop_display_xs; }
        &--desktop-header-xl--large { @include typographyToken.alias_desktop_header_xl; }
        &--desktop-header-lg--large { @include typographyToken.alias_desktop_header_lg; }
        &--desktop-header-md--large { @include typographyToken.alias_desktop_header_md; }
        &--desktop-header-sm--large { @include typographyToken.alias_desktop_header_sm; }
        &--desktop-header-xs--large { @include typographyToken.alias_desktop_header_xs; }
        &--desktop-description-xl--large { @include typographyToken.alias_desktop_description_xl; }
        &--desktop-description-lg--large { @include typographyToken.alias_desktop_description_lg; }
        &--desktop-description-md--large { @include typographyToken.alias_desktop_description_md; }
        &--desktop-description-sm--large { @include typographyToken.alias_desktop_description_sm; }
        &--desktop-description-bold-xl--large { @include typographyToken.alias_desktop_description_bold_xl; }
        &--desktop-description-bold-lg--large { @include typographyToken.alias_desktop_description_bold_lg; }
        &--desktop-description-bold-md--large { @include typographyToken.alias_desktop_description_bold_md; }
        &--desktop-description-bold-sm--large { @include typographyToken.alias_desktop_description_bold_sm; }
        &--desktop-tag-md--large { @include typographyToken.alias_desktop_tag_md; }
        &--desktop-tag-sm--large { @include typographyToken.alias_desktop_tag_sm; }
        &--desktop-tag-xs--large { @include typographyToken.alias_desktop_tag_xs; }
        &--desktop-quote-xl--large { @include typographyToken.alias_desktop_quote_xl; }
        &--desktop-quote-lg--large { @include typographyToken.alias_desktop_quote_lg; }
        &--desktop-caption-sm--large { @include typographyToken.alias_desktop_caption_sm; }
        &--desktop-byline-sm--large { @include typographyToken.alias_desktop_byline_sm; }
        &--desktop-byline-xs--large { @include typographyToken.alias_desktop_byline_xs; }
        &--desktop-credit-xs--large { @include typographyToken.alias_desktop_credit_xs; }
        &--mobile-display-xxl--large { @include typographyToken.alias_mobile_display_xxl; }
        &--mobile-display-xl--large { @include typographyToken.alias_mobile_display_xl; }
        &--mobile-display-lg--large { @include typographyToken.alias_mobile_display_lg; }
        &--mobile-display-md--large { @include typographyToken.alias_mobile_display_md; }
        &--mobile-display-sm--large { @include typographyToken.alias_mobile_display_sm; }
        &--mobile-display-xs--large { @include typographyToken.alias_mobile_display_xs; }
        &--mobile-header-xl--large { @include typographyToken.alias_mobile_header_xl; }
        &--mobile-header-lg--large { @include typographyToken.alias_mobile_header_lg; }
        &--mobile-header-md--large { @include typographyToken.alias_mobile_header_md; }
        &--mobile-header-sm--large { @include typographyToken.alias_mobile_header_sm; }
        &--mobile-header-xs--large { @include typographyToken.alias_mobile_header_xs; }
        &--mobile-description-xl--large { @include typographyToken.alias_mobile_description_xl; }
        &--mobile-description-lg--large { @include typographyToken.alias_mobile_description_lg; }
        &--mobile-description-md--large { @include typographyToken.alias_mobile_description_md; }
        &--mobile-description-sm--large { @include typographyToken.alias_mobile_description_sm; }
        &--mobile-description-bold-xl--large { @include typographyToken.alias_mobile_description_bold_xl; }
        &--mobile-description-bold-lg--large { @include typographyToken.alias_mobile_description_bold_lg; }
        &--mobile-description-bold-md--large { @include typographyToken.alias_mobile_description_bold_md; }
        &--mobile-description-bold-sm--large { @include typographyToken.alias_mobile_description_bold_sm; }
        &--mobile-tag-md--large { @include typographyToken.alias_mobile_tag_md; }
        &--mobile-tag-sm--large { @include typographyToken.alias_mobile_tag_sm; }
        &--mobile-tag-xs--large { @include typographyToken.alias_mobile_tag_xs; }
        &--mobile-quote-xl--large { @include typographyToken.alias_mobile_quote_xl; }
        &--mobile-quote-lg--large { @include typographyToken.alias_mobile_quote_lg; }
        &--mobile-caption-sm--large { @include typographyToken.alias_mobile_caption_sm; }
        &--mobile-byline-sm--large { @include typographyToken.alias_mobile_byline_sm; }
        &--mobile-byline-xs--large { @include typographyToken.alias_mobile_byline_xs; }
        &--mobile-credit-xs--large { @include typographyToken.alias_mobile_credit_xs; }
        &--icon-light-xxl--large { @include typographyToken.alias_icon_light_xxl; }
        &--icon-light-xl--large { @include typographyToken.alias_icon_light_xl; }
        &--icon-light-lg--large { @include typographyToken.alias_icon_light_lg; }
        &--icon-light-md--large { @include typographyToken.alias_icon_light_md; }
        &--icon-light-sm--large { @include typographyToken.alias_icon_light_sm; }
        &--icon-light-xs--large { @include typographyToken.alias_icon_light_xs; }
        &--icon-heavy-xxl--large { @include typographyToken.alias_icon_heavy_xxl; }
        &--icon-heavy-xl--large { @include typographyToken.alias_icon_heavy_xl; }
        &--icon-heavy-lg--large { @include typographyToken.alias_icon_heavy_lg; }
        &--icon-heavy-md--large { @include typographyToken.alias_icon_heavy_md; }
        &--icon-heavy-sm--large { @include typographyToken.alias_icon_heavy_sm; }
        &--icon-heavy-xs--large { @include typographyToken.alias_icon_heavy_xs; }
        &--icon-brands-xxl--large { @include typographyToken.alias_icon_brands_xxl; }
        &--icon-brands-xl--large { @include typographyToken.alias_icon_brands_xl; }
        &--icon-brands-lg--large { @include typographyToken.alias_icon_brands_lg; }
        &--icon-brands-md--large { @include typographyToken.alias_icon_brands_md; }
        &--icon-brands-sm--large { @include typographyToken.alias_icon_brands_sm; }
        &--icon-brands-xs--large { @include typographyToken.alias_icon_brands_xs; }
        &--ui-buttons-xl--large { @include typographyToken.alias_ui_buttons_xl; }
        &--ui-buttons-lg--large { @include typographyToken.alias_ui_buttons_lg; }
        &--ui-buttons-md--large { @include typographyToken.alias_ui_buttons_md; }
        &--ui-buttons-sm--large { @include typographyToken.alias_ui_buttons_sm; }
        &--ui-buttons-xs--large { @include typographyToken.alias_ui_buttons_xs; }
        &--ui-forms-label-lg--large { @include typographyToken.alias_ui_forms_label_lg; }
        &--ui-forms-label-md--large { @include typographyToken.alias_ui_forms_label_md; }
        &--ui-forms-label-sm--large { @include typographyToken.alias_ui_forms_label_sm; }
        &--ui-forms-label-xs--large { @include typographyToken.alias_ui_forms_label_xs; }
        &--ui-forms-input-lg--large { @include typographyToken.alias_ui_forms_input_lg; }
        &--ui-forms-input-md--large { @include typographyToken.alias_ui_forms_input_md; }
        &--ui-forms-input-sm--large { @include typographyToken.alias_ui_forms_input_sm; }
        &--ui-forms-input-xs--large { @include typographyToken.alias_ui_forms_input_xs; }
        &--ui-menu-lg--large { @include typographyToken.alias_ui_menu_lg; }
        &--ui-menu-md--large { @include typographyToken.alias_ui_menu_md; }
        &--ui-menu-sm--large { @include typographyToken.alias_ui_menu_sm; }
        &--ui-menu-xs--large { @include typographyToken.alias_ui_menu_xs; }
    }

    // @postinstallEnd - don't remove this line
}