@use 'rcl/tokens/typographyToken';
@use './util';

:root {
    // variables
    --aside-width: 0px;
    --header-height: 0px;
    --footer-height: #{util.pxToRem(24)};
}


body {
    font-size: 16px;
    font-family: IBM Plex Sans,Helvetica,sans-serif;
    width: 100%;
    height: 100%;
}