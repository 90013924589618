@use "rcl/tokens/fontSizesToken";
@use "styles/util";

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(1.5em, 0);
    }
}

.c-loader {
    display: flex;
    font-size: util.gutter(1);
    width: 100%;
    justify-content: center;

    &--sm {
        font-size: util.gutter(0, 2); // TODO verify this
    }

    &--lg {
        font-size: util.gutter(1, 2); // TDO verify this
    }

    &--center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    &__container {
        display: inline-block;
        position: relative;
        height: 0.8em;
        width: 3.8em;
    }

    &__dot {
        position: absolute;
        top: 0;
        width: 0.8em;
        height: 0.8em;
        border-radius: 50%;
        background: #888888; // TODO theme this
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &--1 {
            left: 0;
            animation: lds-ellipsis1 0.6s infinite;
        }

        &--2 {
            left: 0;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &--3 {
            left: 1.5em;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &--4 {
            left: 3em;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }
}
